// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-textarea {
  background: var(--ion-card-background);
  color: var(--ion-text-color);
  --placeholder-color: rgba(var(--ion-text-color-rgb), 0.7);
  border-radius: 15px;
  --padding-start: 15px;
  --padding-end: 15px;
  --padding-top: 15px;
  --padding-bottom: 15px;
}

ion-button {
  margin: 20px auto;
  width: 100%;
}

.required-field {
  float: right;
  font-size: 11px;
  margin-top: -10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/charging-station-view/contribute/modals/leave-comment/leave-comment.component.scss"],"names":[],"mappings":"AACA;EACE,sCAAA;EACA,4BAAA;EACA,yDAAA;EACA,mBAAA;EACA,qBAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,WAAA;AAAF;;AAGA;EACE,YAAA;EACA,eAAA;EACA,iBAAA;AAAF","sourcesContent":["\r\nion-textarea {\r\n  background: var(--ion-card-background);\r\n  color: var(--ion-text-color);\r\n  --placeholder-color: rgba(var(--ion-text-color-rgb), 0.7);\r\n  border-radius: 15px;\r\n  --padding-start: 15px;\r\n  --padding-end: 15px;\r\n  --padding-top: 15px;\r\n  --padding-bottom: 15px;\r\n}\r\n\r\nion-button {\r\n  margin: 20px auto;\r\n  width: 100%;\r\n}\r\n\r\n.required-field {\r\n  float: right;\r\n  font-size: 11px;\r\n  margin-top: -10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
