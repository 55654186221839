// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  border-radius: 0;
  --padding-bottom: 0px;
}
ion-toolbar .back-button {
  background-color: var(--ion-color-sidepanel-header);
  color: var(--ion-color-sidepanel-header-contrast);
  --background-hover-opacity: 0;
}
ion-toolbar h2 {
  font-size: 18px;
  padding-top: 15px;
  color: var(--ion-color-sidepanel-header-contrast);
}

ion-content {
  --max-width: 500px;
  --margin: 20px auto;
}
ion-content .header {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 40px;
}
ion-content h3 {
  font-size: 20px;
  font-weight: 500;
  color: var(--ion-color-primary);
}
ion-content .has-password-display-btn ion-input {
  --border-radius: 30px;
}
ion-content ion-input {
  background-color: var(--ion-card-background);
  color: var(--ion-text-color);
  --placeholder-color: rgba(var(--ion-text-color-rgb), 0.7);
}
ion-content ion-button {
  width: 100%;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/set-password/set-password.page.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,qBAAA;AACF;AACE;EACE,mDAAA;EACA,iDAAA;EACA,6BAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,iDAAA;AAAJ;;AAIA;EAKE,kBAAA;EACA,mBAAA;AALF;AAOE;EACE,YAAA;EACA,gBAAA;EACA,mBAAA;AALJ;AAQE;EACE,eAAA;EACA,gBAAA;EACA,+BAAA;AANJ;AAUI;EACE,qBAAA;AARN;AAYE;EACE,4CAAA;EACA,4BAAA;EACA,yDAAA;AAVJ;AAaE;EACE,WAAA;EACA,gBAAA;AAXJ","sourcesContent":["ion-toolbar {\r\n  border-radius: 0;\r\n  --padding-bottom: 0px;\r\n\r\n  .back-button {\r\n    background-color: var(--ion-color-sidepanel-header);\r\n    color: var(--ion-color-sidepanel-header-contrast);\r\n    --background-hover-opacity: 0;\r\n  }\r\n\r\n  h2 {\r\n    font-size: 18px;\r\n    padding-top: 15px;\r\n    color: var(--ion-color-sidepanel-header-contrast);\r\n  }\r\n}\r\n\r\nion-content {\r\n  // --background: url(../../assets/img/splash.png) bottom center / contain no-repeat\r\n  //   var(--ion-color-lightblue);\r\n  // --color: var(--ion-color-secondary);\r\n\r\n  --max-width: 500px;\r\n  --margin: 20px auto;\r\n\r\n  .header {\r\n    margin: auto;\r\n    margin-top: 30px;\r\n    margin-bottom: 40px;\r\n  }\r\n\r\n  h3 {\r\n    font-size: 20px;\r\n    font-weight: 500;\r\n    color: var(--ion-color-primary);\r\n  }\r\n\r\n  .has-password-display-btn {\r\n    ion-input {\r\n      --border-radius: 30px;\r\n    }\r\n  }\r\n\r\n  ion-input {\r\n    background-color: var(--ion-card-background);\r\n    color: var(--ion-text-color);\r\n    --placeholder-color: rgba(var(--ion-text-color-rgb), 0.7);\r\n  }\r\n\r\n  ion-button {\r\n    width: 100%;\r\n    margin-top: 20px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
