// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  border-radius: 0;
  --padding-bottom: 0px;
}
ion-toolbar .back-button {
  background-color: var(--ion-color-sidepanel-header);
  color: var(--ion-color-sidepanel-header-contrast);
  --background-hover-opacity: 0;
}
ion-toolbar ion-title {
  font-size: 18px;
  color: var(--ion-color-sidepanel-header-contrast);
}

ion-content {
  --max-width: 500px;
  --margin: 20px auto;
}
ion-content .header {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 40px;
}
ion-content h3 {
  font-size: 18px;
  font-weight: 500;
  color: var(--ion-color-primary);
}
ion-content .has-password-display-btn ion-input {
  --border-radius: 10px;
}
ion-content .required-field {
  float: right;
  font-size: 11px;
  margin-bottom: 5px;
}
ion-content .forgot {
  margin-top: 40px;
  display: inline-block;
  color: var(--ion-color-secondary);
  background-color: transparent;
}
ion-content .forgot ion-icon {
  margin-right: 5px;
}
ion-content ion-input {
  background-color: var(--ion-card-background);
  color: var(--ion-text-color);
  --placeholder-color: rgba(var(--ion-text-color-rgb), 0.7);
}
ion-content ion-button.button-large {
  width: 100%;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.page.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,qBAAA;AACF;AACE;EACE,mDAAA;EACA,iDAAA;EACA,6BAAA;AACJ;AAEE;EACE,eAAA;EACA,iDAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,mBAAA;AADF;AAGE;EACE,YAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAIE;EACE,eAAA;EACA,gBAAA;EACA,+BAAA;AAFJ;AAMI;EACE,qBAAA;AAJN;AAQE;EACE,YAAA;EACA,eAAA;EACA,kBAAA;AANJ;AASE;EACE,gBAAA;EACA,qBAAA;EACA,iCAAA;EACA,6BAAA;AAPJ;AASI;EACE,iBAAA;AAPN;AAWE;EACE,4CAAA;EACA,4BAAA;EACA,yDAAA;AATJ;AAYE;EACE,WAAA;EACA,gBAAA;AAVJ","sourcesContent":["ion-toolbar {\r\n  border-radius: 0;\r\n  --padding-bottom: 0px;\r\n\r\n  .back-button {\r\n    background-color: var(--ion-color-sidepanel-header);\r\n    color: var(--ion-color-sidepanel-header-contrast);\r\n    --background-hover-opacity: 0;\r\n  }\r\n\r\n  ion-title {\r\n    font-size: 18px;\r\n    color: var(--ion-color-sidepanel-header-contrast);\r\n  }\r\n}\r\n\r\nion-content {\r\n  --max-width: 500px;\r\n  --margin: 20px auto;\r\n\r\n  .header {\r\n    margin: auto;\r\n    margin-top: 30px;\r\n    margin-bottom: 40px;\r\n  }\r\n\r\n  h3 {\r\n    font-size: 18px;\r\n    font-weight: 500;\r\n    color: var(--ion-color-primary);\r\n  }\r\n\r\n  .has-password-display-btn {\r\n    ion-input {\r\n      --border-radius: 10px;\r\n    }\r\n  }\r\n\r\n  .required-field {\r\n    float: right;\r\n    font-size: 11px;\r\n    margin-bottom: 5px;\r\n  }\r\n\r\n  .forgot {\r\n    margin-top: 40px;\r\n    display: inline-block;\r\n    color: var(--ion-color-secondary);\r\n    background-color: transparent;\r\n\r\n    ion-icon {\r\n      margin-right: 5px;\r\n    }\r\n  }\r\n\r\n  ion-input {\r\n    background-color: var(--ion-card-background);\r\n    color: var(--ion-text-color);\r\n    --placeholder-color: rgba(var(--ion-text-color-rgb), 0.7);\r\n  }\r\n\r\n  ion-button.button-large {\r\n    width: 100%;\r\n    margin-top: 20px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
