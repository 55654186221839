import { Injectable } from '@angular/core';

import { Auth } from '@angular/fire/auth';
import { getAuth, EmailAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, OAuthProvider, GoogleAuthProvider, FacebookAuthProvider, User as FirebaseUser, UserCredential, reauthenticateWithCredential, updateEmail, updatePassword, sendPasswordResetEmail, fetchSignInMethodsForEmail, linkWithCredential, signOut } from 'firebase/auth';
import { AlertController, Platform } from '@ionic/angular';
import firebase from 'firebase/app';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '../models/user.interface';

import { GraphqlService } from './graphql.service';

// import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  collectionNamePlural = 'users';
  collectionNameSingular = 'user';

  grahqlQueries: {
    getAllItems: string;
    getItem: string;
    getItemWithFirebaseAuthId: string;
    getItemWithUsername: string;
    deleteItem: string;
    updateItem: string;
    updateBatteryChargingValues: string;
    updateEmspsValues: string;
    addItem: string;
    addFcmTokenToUser: string;
    doesUserExistsWithEmail: string;
    loginWithEmailAndFirebaseAuthId: string;
    updateFirebaseAuthId: string;
    updateField: string;
    updateStripeUserId: string;
    getConnectedAccountInfo: string;
    getConnectedAccountPayments: string;
    getWordpressToken: string;
    createStripeLoginLink: string;
    updateVerifiedEmailOfUser: string;
    getItemWithEmail: string;
    resendVerificationEmail: string;
  } = {
    getAllItems: `
    query users($query: String, $page: Int, $itemsPerPage: Int, $orderBy: String, $direction: String, $isPro: Boolean, $isAdmin: Boolean, $emailIsVerified: Boolean, $newsletterEnabled: Boolean, $profilePictureExist: Boolean) {
      users(
        query: $query,
        page: $page,
        itemsPerPage: $itemsPerPage,
        orderBy: $orderBy,
        direction: $direction,
        isPro: $isPro,
        isAdmin: $isAdmin,
        emailIsVerified: $emailIsVerified,
        newsletterEnabled: $newsletterEnabled,
        profilePictureExist: $profilePictureExist
      ) {
          id
          firstname
          lastname
          username
          fullName
          stripeUserId
          email
          profilePictureUrl
          isAdmin
          messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified
          createdAt
          lastLoginDate
          isLogged
          newsletterEnabled

          userBySponsorUserId {
            firstname
            lastname
            email
          }

          proMatchingQuotations {
            id
            userId
            fieldId
            value
          }
        }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    user(id: $id) {
          id
          firstname
          lastname
          username
          fullName
          stripeUserId
          email
          firebaseAuthIds
          profilePictureUrl
          isAdmin
          messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          batteryChargeMinimum
          batteryChargeMaximum
          selectedEmspsId
          newsletterEnabled
          darkThemeEnabled
          gender
          birthDate
          phone
          company
          description
          whoCanSendMeMessages
          whoCanInviteMeToBeFriend
          lastLoginDate
          isLogged
          createdAt
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified
          customerId
          stripeUserId

          userBySponsorUserId {
            firstname
            lastname
            email
          }

          proMatchingQuotations {
            id
            userId
            fieldId
            value
          }

		    }
		  }
		`,
    getConnectedAccountInfo: `
		  query getConnectedAccountInfo($accountId: String!) {
		    connectedAccountInfo(accountId: $accountId)
		  }
		`,
    getConnectedAccountPayments: `
		  query getConnectedAccountPayments($accountId: String!, $lastItem: String) {
		    connectedAccountPayments(accountId: $accountId, lastItem: $lastItem)
		  }
		`,
    getItemWithFirebaseAuthId: `
		  query getItemWithFirebaseAuthId($firebaseAuthId: String!) {
		    user(firebaseAuthId: $firebaseAuthId) {
          id
          customerId
          firstname
          lastname
          username
          stripeUserId
          fullName
          email
          profilePictureUrl
          isAdmin
          messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          batteryChargeMinimum
          batteryChargeMaximum
          selectedEmspsId
          newsletterEnabled
          darkThemeEnabled
          gender
          birthDate
          phone
          company
          description
          whoCanSendMeMessages
          whoCanInviteMeToBeFriend
          createdAt
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified

          proMatchingQuotations {
            id
            userId
            fieldId
            value
          }


          userBadges {
            badge {
              id
              name
              imageUrl
            }
          }
		    }
		  }
		`,
    getItemWithUsername: `
		  query getItemWithUsername($username: String!, $userToIgnoreId: String) {
		    user(username: $username, userToIgnoreId: $userToIgnoreId) {
          id
          firstname
          lastname
          username
          stripeUserId
          fullName
          email
          profilePictureUrl
          isAdmin
          messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          batteryChargeMinimum
          batteryChargeMaximum
          selectedEmspsId
          newsletterEnabled
          darkThemeEnabled
          gender
          birthDate
          phone
          company
          description
          whoCanSendMeMessages
          whoCanInviteMeToBeFriend
          lastLoginDate
          isLogged
          createdAt
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified

          proMatchingQuotations {
            id
            userId
            fieldId
            value
          }


          userBadges {
            badge {
              id
              name
              imageUrl
            }
          }
        }
		  }
		`,
    getItemWithEmail: `
		  query getItemWithUsername($email: String!) {
		    user(email: $email) {
          id
          firstname
          lastname
          username
          stripeUserId
          fullName
          email
          profilePictureUrl
          isAdmin
          messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          batteryChargeMinimum
          batteryChargeMaximum
          selectedEmspsId
          newsletterEnabled
          darkThemeEnabled
          gender
          birthDate
          phone
          company
          description
          whoCanSendMeMessages
          whoCanInviteMeToBeFriend
          lastLoginDate
          isLogged
          createdAt
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified

          proMatchingQuotations {
            id
            userId
            fieldId
            value
          }


          userBadges {
            badge {
              id
              name
              imageUrl
            }
          }
        }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteUser(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem(
        $id: ID!,
        $firstname: String,
        $lastname: String,
        $username: String,
        $email: String,
        $customerId: String,
        $stripeUserId: String,
        $firebaseAuthIds: [String],
        $profilePictureUrl: String,
        $isAdmin: Boolean,
        $gender: String,
        $birthDate: String,
        $phone: String,
        $company: String,
        $description: String,
        $whoCanSendMeMessages: String,
        $whoCanInviteMeToBeFriend: String,
        $isPro: Boolean,
        $gravityFormEntryId: Int,
        $corporateName: String,
        $siren: String,
        $fonction: String,
        $address: JSON
    ) {
				updateUser(
          id: $id,
          firstname: $firstname,
          lastname: $lastname,
          username: $username,
          email: $email,
          customerId: $customerId,
          stripeUserId: $stripeUserId,
          firebaseAuthIds: $firebaseAuthIds,
          profilePictureUrl: $profilePictureUrl,
          isAdmin: $isAdmin,
          gender: $gender,
          birthDate: $birthDate,
          phone: $phone,
          company: $company,
          description: $description,
          whoCanSendMeMessages: $whoCanSendMeMessages,
          whoCanInviteMeToBeFriend: $whoCanInviteMeToBeFriend,
          isPro: $isPro,
          gravityFormEntryId: $gravityFormEntryId,
          corporateName: $corporateName,
          siren: $siren,
          fonction: $fonction,
          address: $address
        ) {
					id
				}
			}
		`,
    updateBatteryChargingValues: `
			mutation updateBatteryChargingValues($id: ID!, $batteryChargeMinimum: Int!, $batteryChargeMaximum: Int!) {
				updateBatteryChargingValues(id: $id, batteryChargeMinimum: $batteryChargeMinimum, batteryChargeMaximum: $batteryChargeMaximum) {
					id
				}
			}
		`,
    updateEmspsValues: `
    mutation updateEmspsValues($id: ID!, $selectedEmspsId: [String!]!) {
      updateEmspsValues(id: $id, selectedEmspsId: $selectedEmspsId) {
        id
      }
    }
  `,
    updateFirebaseAuthId: `
			mutation updateFirebaseAuthId($email: String!, $firebaseAuthId: String!) {
				updateFirebaseAuthId(email: $email, firebaseAuthId: $firebaseAuthId) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem(
        $firstname: String!,
        $lastname: String!,
        $username: String!,
        $email: String!,
        $firebaseAuthIds: [String],
        $gender: String,
        $birthDate: String,
        $phone: String,
        $company: String,
        $description: String,
        $whoCanSendMeMessages: String,
        $whoCanInviteMeToBeFriend: String,
        $isPro: Boolean,
        $gravityFormEntryId: Int,
        $corporateName: String,
        $siren: String,
        $fonction: String,
        $address: JSON,
        $newsletterEnabled: Boolean,
        $sponsorUserId: String
    ) {
				addUser(
          firstname: $firstname,
          lastname: $lastname,
          username: $username,
          email: $email,
          firebaseAuthIds: $firebaseAuthIds,
          gender: $gender,
          birthDate: $birthDate,
          phone: $phone,
          company: $company,
          description: $description,
          whoCanSendMeMessages: $whoCanSendMeMessages,
          whoCanInviteMeToBeFriend: $whoCanInviteMeToBeFriend,
          isPro: $isPro,
          gravityFormEntryId: $gravityFormEntryId,
          corporateName: $corporateName,
          siren: $siren,
          fonction: $fonction,
          address: $address,
          newsletterEnabled: $newsletterEnabled,
          sponsorUserId: $sponsorUserId,
        ) {
					id
				}
			}
		`,
    resendVerificationEmail: `
      mutation ResendVerificationEmail($email: String!) {
        resendVerificationEmail(email: $email) {
          id
          email
        }
      }
    `,
    addFcmTokenToUser: `
      mutation addFcmTokenToUser(
        $userId: String!,
        $token: String!,
    ) {
      addFcmTokenToUser(
          userId: $userId,
          token: $token,
        ) {
          id
        }
      }
    `,
    doesUserExistsWithEmail: `
			query doesUserExistsWithEmail($email: String!) {
				doesUserExistsWithEmail(email: $email)
			}
		`,
    loginWithEmailAndFirebaseAuthId: `
			query loginWithEmailAndFirebaseAuthId($email: String!, $firebaseAuthId: String!) {
				loginWithEmailAndFirebaseAuthId(email: $email, firebaseAuthId: $firebaseAuthId) {
					id
					firstname
					lastname
					username
					fullName
					email
					profilePictureUrl
					isAdmin
					messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          newsletterEnabled
          darkThemeEnabled
          gender
          stripeUserId
          birthDate
          phone
          company
          description
          whoCanSendMeMessages
          whoCanInviteMeToBeFriend
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified
        }
			}
		`,

    updateField: `
			mutation updateUserField($id: ID!, $field: String!, $value: String!) {
				updateUserField(id: $id, field: $field, value: $value) {
					id
				}
			}
		`,
    updateStripeUserId: `
			mutation updateStripeUserId($id: ID!, $authorizationCode: String!) {
				updateStripeUserId(id: $id,authorizationCode: $authorizationCode) {
					id
				}
			}
		`,
    getWordpressToken: `
    query getWordpressToken($id: ID!, $password: String) {
      getWordpressToken(id: $id, password: $password)
    }
  `,
    createStripeLoginLink: `
      mutation createStripeLoginLink($id: ID!) {
        createStripeLoginLink(id: $id)
      }
    `,
    updateVerifiedEmailOfUser: `
    mutation updateVerifiedEmailOfUser($id: ID!) {
      updateVerifiedEmailOfUser(id: $id) {
        id
        emailIsVerified
      }
    }
  `
  };

  userAuth: FirebaseUser;
  userAuthObservable: Observable<FirebaseUser>;

  currentUser: User;
  currentUserObservable: Observable<User>;

  isLogged: boolean;
  isLoggedObservable: Observable<boolean>;

  darkThemeEnabled: boolean;
  darkThemeEnabledObservable: Observable<boolean>;

  private userAuthBehavior: BehaviorSubject<FirebaseUser>;
  private currentUserBehavior: BehaviorSubject<User>;
  private isLoggedBehavior: BehaviorSubject<boolean>;
  private darkThemeEnabledBehavior: BehaviorSubject<boolean>;

  constructor(
    private graphqlService: GraphqlService,
    private auth: Auth,
    private platform: Platform,
    private alertController: AlertController
  ) {
    this.darkThemeEnabledBehavior = new BehaviorSubject<boolean>(null);
    this.darkThemeEnabledObservable = this.darkThemeEnabledBehavior.asObservable();
    this.darkThemeEnabledObservable.subscribe(
      (darkThemeEnabled: boolean) => (this.darkThemeEnabled = darkThemeEnabled)
    );

    this.userAuthBehavior = new BehaviorSubject<FirebaseUser>(null);
    this.userAuthObservable = this.userAuthBehavior.asObservable();
    this.userAuthObservable.subscribe((userAuth: FirebaseUser) => (this.userAuth = userAuth));

    this.currentUserBehavior = new BehaviorSubject<User>(null);
    this.currentUserObservable = this.currentUserBehavior.asObservable();
    this.currentUserObservable.subscribe((currentUser: User) => {
      this.currentUser = currentUser;

      if (this.currentUser && this.currentUser.darkThemeEnabled !== null) {
        this.setDarkThemeEnabled(this.currentUser.darkThemeEnabled);
      } else {
        this.setDarkThemeEnabled(null);
      }
    });

    this.isLoggedBehavior = new BehaviorSubject<boolean>(null);
    this.isLoggedObservable = this.isLoggedBehavior.asObservable();
    this.isLoggedObservable.subscribe((isLogged: boolean) => (this.isLogged = isLogged));

    this.auth.onAuthStateChanged((userAuth: FirebaseUser) => {
      this.setUserAuth(userAuth);

      this.refreshCurrentUser();
    });
  }

  async refreshCurrentUser(): Promise<void> {
    if (this.userAuth && this.userAuth.uid) {
      this.getUserFromFirebaseAuthId(this.userAuth.uid)
        .then((user: User) => {
          this.setCurrentUser(user);
          this.setIsLogged(true);
        })
        .catch(err => {
          //console.log(err);
          this.setCurrentUser(null);
          this.setIsLogged(false);
        });
    } else {
      this.setCurrentUser(null);
      this.setIsLogged(false);
    }
  }

  setUserAuth(userAuth: FirebaseUser): void {
    this.userAuthBehavior.next(userAuth);
  }

  setCurrentUser(currentUser: User): void {
    this.currentUserBehavior.next(currentUser);
  }

  setIsLogged(isLogged: boolean): void {
    this.isLoggedBehavior.next(isLogged);
  }

  setDarkThemeEnabled(darkThemeEnabled: boolean): void {
    this.darkThemeEnabledBehavior.next(darkThemeEnabled);
  }

  async doesUserExistsWithEmail(email: string): Promise<{
    exist: boolean;
    hasAuthConfigured: boolean;
  }> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.doesUserExistsWithEmail,
        {
          email
        }
      );

      return result.doesUserExistsWithEmail as {
        exist: boolean;
        hasAuthConfigured: boolean;
      };
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async add(data: User): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.addItem, data);

      if (result.addUser) {
        return result.addUser as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async resendVerificationEmail(email: string): Promise<User> {
    try {
      // Utilisation de la notation shorthand pour { email: email }
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.resendVerificationEmail, { email });

      if (result.resendVerificationEmail) {
        // Créez et présentez une alerte pour confirmer l'envoi
        const alert = await this.alertController.create({
          header: 'C\'est parti 👍',
          message: 'Un lien de validation a été envoyé par email, vérifie ta boîte de réception et tes spams !',
          buttons: ['OK']
        });

        await alert.present();

        return result.resendVerificationEmail as User;
      }
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  async updateEmailVerified(idUser: string): Promise<void> {
    try {
      //console.log('idUser ', idUser);

      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateVerifiedEmailOfUser,
        { id: idUser }
      );
      //console.log('result ', result);
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addNewFcmTokenToCurrentUser(token: string): Promise<User> {
    if (this.currentUser) {
      return this.addNewFcmTokenToUser(this.currentUser.id, token);
    }

    return null;
  }

  async addNewFcmTokenToUser(userId: string, token: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.addFcmTokenToUser,
        {
          userId,
          token
        }
      );

      if (result.addFcmTokenToUser) {
        await this.refreshCurrentUser();

        return result.addFcmTokenToUser as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getConnectedAccountInfo(accountId: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getConnectedAccountInfo,
        {
          accountId
        }
      );

      if (result.connectedAccountInfo) {
        return result.connectedAccountInfo;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getConnectedAccountPayments(accountId: string, lastItem: string = null): Promise<any> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getConnectedAccountPayments,
        {
          accountId,
          lastItem
        }
      );

      if (result.connectedAccountPayments) {
        return result.connectedAccountPayments;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getWithUsername(username: string, userToIgnoreId: string = null): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getItemWithUsername,
        {
          username,
          userToIgnoreId
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getUserByEmail(email: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getItemWithEmail,
        {
          email
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: User): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateBatteryChargingValues(
    userId: string,
    batteryChargeMinimum: number,
    batteryChargeMaximum: number
  ): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateBatteryChargingValues,
        {
          id: userId,
          batteryChargeMinimum,
          batteryChargeMaximum
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateEmspsValues(
    userId: string,
    selectedEmspsId: string[]
  ): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateEmspsValues,
        {
          id: userId,
          selectedEmspsId
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateFirebaseAuthId(email: string, firebaseAuthId: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateFirebaseAuthId,
        {
          email,
          firebaseAuthId
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateCurrentUser(data: User, password: string): Promise<User> {
    try {
      if (data.email) {
        const credential = EmailAuthProvider.credential(
          this.currentUser.email,
          password
        );

        await reauthenticateWithCredential(this.userAuth, credential);

        await updateEmail(this.userAuth, data.email);

        const currentUserObj = Object.assign({}, this.currentUser);

        const user: User = await this.update(Object.assign(currentUserObj, data));

        this.refreshCurrentUser();

        return user;
      } else {
        return this.update(Object.assign(this.currentUser, data));
      }
    } catch (err) {
      console.error(err);

      switch (err.code) {
        case 'auth/user-mismatch':
          err.message = 'Compte membre introuvable.';
          break;
        case 'auth/user-not-found':
          err.message = 'Compte membre introuvable.';
          break;
        case 'auth/invalid-credential':
          err.message = 'L\'authentification du membre n\'est plus valide.';
          break;
        case 'auth/invalid-email':
          err.message = 'L\'email est invalide.';
          break;
        case 'auth/wrong-password':
          err.message = 'Le mot de passe est invalide.';
          break;
        case 'auth/too-many-enquiries':
          err.message = 'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.';
          break;
        case 'auth/email-already-in-use':
          err.message = 'L\'email est déjà utilisé par un autre membre.';
          break;
      }

      return Promise.reject(err);
    }
  }

  async updateField(id: string, field: string, value: any): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.updateField, {
        id,
        field,
        value: JSON.stringify(value)
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateStripeUserId(id: string, authorizationCode: any): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateStripeUserId,
        {
          id,
          authorizationCode
        }
      );

      if (result[this.collectionNameSingular]) {
        this.refreshCurrentUser();

        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async createStripeLoginUrl(id: string): Promise<string> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.createStripeLoginLink,
        {
          id
        }
      );

      return result.createStripeLoginLink;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async deleteCurrentUser(password: string): Promise<void> {
    try {
      const credential = EmailAuthProvider.credential(
        this.currentUser.email,
        password
    );

      await reauthenticateWithCredential(this.userAuth, credential);

      await this.delete(this.currentUser.id);
    } catch (err) {
      console.error(err);

      switch (err.code) {
        case 'auth/user-mismatch':
          err.message = 'Compte membre introuvable.';
          break;
        case 'auth/user-not-found':
          err.message = 'Compte membre introuvable.';
          break;
        case 'auth/invalid-credential':
          err.message = 'L\'authentification du membre n\'est plus valide.';
          break;
        case 'auth/invalid-email':
          err.message = 'L\'email est invalide.';
          break;
        case 'auth/wrong-password':
          err.message = 'Le mot de passe est invalide.';
          break;
        case 'auth/too-many-enquiries':
          err.message = 'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.';
          break;
        case 'auth/email-already-in-use':
          err.message = 'L\'email est déjà utilisé par un autre membre.';
          break;
      }

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(data: any): Promise<User[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        data
      );

      const items: User[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as User);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async login(email: string, password: string): Promise<User> {
    try {
      const userCredential: UserCredential = await signInWithEmailAndPassword(
        getAuth(),
        email,
        password
      );

      if (userCredential) {
        const result: any = await this.graphqlService.apolloWatchQuery(
          this.grahqlQueries.loginWithEmailAndFirebaseAuthId,
          {
            email,
            firebaseAuthId: userCredential.user.uid
          }
        );

        if (result.loginWithEmailAndFirebaseAuthId) {
          return result.loginWithEmailAndFirebaseAuthId as User;
        }
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async loginWithApple(): Promise<{
    user: User;
    userCredential: UserCredential;
  }> {
    try {
      const userCredential: UserCredential = await this.signInWithApple();

      if (userCredential) {
        const result: any = await this.graphqlService.apolloWatchQuery(
          this.grahqlQueries.loginWithEmailAndFirebaseAuthId,
          {
            email: userCredential.user.email,
            firebaseAuthId: userCredential.user.uid
          }
        );

        return {
          user: result.loginWithEmailAndFirebaseAuthId
            ? (result.loginWithEmailAndFirebaseAuthId as User)
            : null,
          userCredential
        };
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async loginWithGoogle(): Promise<{
    user: User;
    userCredential: UserCredential;
  }> {
    try {
      const userCredential: UserCredential = await this.signInWithGoogle();

      if (userCredential) {
        const result: any = await this.graphqlService.apolloWatchQuery(
          this.grahqlQueries.loginWithEmailAndFirebaseAuthId,
          {
            email: userCredential.user.email,
            firebaseAuthId: userCredential.user.uid
          }
        );
        //console.log(result);
        return {
          user: result.loginWithEmailAndFirebaseAuthId
            ? (result.loginWithEmailAndFirebaseAuthId as User)
            : null,
          userCredential
        };
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async loginWithFacebook(): Promise<{
    user: User;
    userCredential: UserCredential;
  }> {
    try {
      const userCredential: UserCredential = await this.signInWithFacebook();
      //console.log('userCredential', userCredential);
      if (userCredential) {
        const result: any = await this.graphqlService.apolloWatchQuery(
          this.grahqlQueries.loginWithEmailAndFirebaseAuthId,
          {
            email: userCredential.user.email,
            firebaseAuthId: userCredential.user.uid
          }
        );

        return {
          user: result.loginWithEmailAndFirebaseAuthId
            ? (result.loginWithEmailAndFirebaseAuthId as User)
            : null,
          userCredential
        };
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async signOut(isDelete = false): Promise<void> {
    if (!isDelete) {
      await this.updateField(this.currentUser.id, 'isLogged', false);
    }

    this.userAuthBehavior.next(null);
    this.currentUserBehavior.next(null);
    this.isLoggedBehavior.next(false);
    this.darkThemeEnabledBehavior.next(false);

    await this.signOutFirebaseAuth();
  }

  register(registerData: User, password: string): Promise<User> {
    try {
      return new Promise((resolve, reject) => {
        createUserWithEmailAndPassword(getAuth(), registerData.email, password)
          .then(async (userCredential: UserCredential) => {
            registerData.firebaseAuthIds = [userCredential.user.uid];

            const user: User = await this.add(registerData);

            await this.refreshCurrentUser();

            resolve(user);
          })
          .catch(async err => {
            switch (err.code) {
              case 'auth/email-already-in-use':
                err.message = 'Email déjà utilisé.';

                try {
                  const existingUserCredential: UserCredential =
                    await signInWithEmailAndPassword(getAuth(), registerData.email, password);

                  registerData.firebaseAuthIds = [existingUserCredential.user.uid];

                  const user: User = await this.add(registerData);

                  await this.refreshCurrentUser();

                  resolve(user);
                } catch (e) {
                  reject(err);
                }
                break;
              case 'auth/invalid-email':
                err.message = 'L\'email est invalide.';
                break;
              case 'auth/operation-not-allowed':
                err.message = 'Opération interdite.';
                break;
              case 'auth/weak-password':
                err.message = 'Le mot de passe n\'est pas suffisamment complexe.';
                break;
            }

            reject(err);
          });
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  registerWithUserCredential(
    registerData: User,
    userCredential: UserCredential
  ): Promise<User> {
    try {
      return new Promise(async (resolve, reject) => {
        registerData.firebaseAuthIds = [userCredential.user.uid];

        const user: User = await this.add(registerData);

        this.refreshCurrentUser();

        resolve(user);
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  setPassword(email: string, password: string): Promise<User> {
    try {
      return new Promise((resolve, reject) => {
        createUserWithEmailAndPassword(getAuth(), email, password)
          .then(async (userCredential: UserCredential) => {
            const firebaseAuthId: string = userCredential.user.uid;

            const user: User = await this.updateFirebaseAuthId(email, firebaseAuthId);

            this.refreshCurrentUser();

            resolve(user);
          })
          .catch(async err => {
            switch (err.code) {
              case 'auth/email-already-in-use':
                err.message = 'Email déjà utilisé.';

                try {
                  const existingUserCredential: UserCredential =
                    await signInWithEmailAndPassword(getAuth(), email, password);

                  const firebaseAuthId: string = existingUserCredential.user.uid;

                  const user: User = await this.updateFirebaseAuthId(email, firebaseAuthId);

                  this.refreshCurrentUser();

                  resolve(user);
                } catch (e) {
                  reject(err);
                }
                break;
              case 'auth/invalid-email':
                err.message = 'L\'email est invalide.';
                break;
              case 'auth/operation-not-allowed':
                err.message = 'Opération interdite.';
                break;
              case 'auth/weak-password':
                err.message = 'Le mot de passe n\'est pas suffisamment complexe.';
                break;
            }

            reject(err);
          });
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async resetPassword(email: string): Promise<void> {
    try {
      await this.resetPasswordFirebaseAuth(email);
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getUserFromFirebaseAuthId(firebaseAuthId: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getItemWithFirebaseAuthId,
        {
          firebaseAuthId
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateCurrentUserPassword(originalPassword: string, newPassword: string): Promise<void> {
    try {
      const credential = EmailAuthProvider.credential(
        this.currentUser.email,
        originalPassword
      );

      await reauthenticateWithCredential(this.userAuth, credential);

      await updatePassword(this.userAuth, newPassword);

    } catch (err) {
      switch (err.code) {
        case 'auth/weak-password':
          err.message = 'Le mot de passe n\'est pas suffisamment sécurisé.';
          break;
        case 'auth/user-mismatch':
          err.message = 'Compte membre introuvable.';
          break;
        case 'auth/user-not-found':
          err.message = 'Compte membre introuvable.';
          break;
        case 'auth/invalid-credential':
          err.message = 'L\'authentification du membre n\'est plus valide.';
          break;
        case 'auth/invalid-email':
          err.message = 'L\'email est invalide.';
          break;
        case 'auth/wrong-password':
          err.message = 'L\'ancien mot de passe est invalide.';
          break;
        case 'auth/too-many-enquiries':
          err.message = 'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.';
          break;
      }

      return Promise.reject(err);
    }
  }

  async getCurrentUserWordpressToken(password: string = null): Promise<string> {
    try {
      if (password) {
        const credential = EmailAuthProvider.credential(
          this.currentUser.email,
          password
        );

        await reauthenticateWithCredential(this.userAuth, credential);
      }

      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.getWordpressToken,
        {
          id: this.currentUser.id,
          password
        }
      );

      return result.getWordpressToken as string;
    } catch (err) {
      console.error(err);

      switch (err.code) {
        case 'auth/user-mismatch':
          err.message = 'Compte membre introuvable.';
          break;
        case 'auth/user-not-found':
          err.message = 'Compte membre introuvable.';
          break;
        case 'auth/invalid-credential':
          err.message = 'L\'authentification du membre n\'est plus valide.';
          break;
        case 'auth/invalid-email':
          err.message = 'L\'email est invalide.';
          break;
        case 'auth/wrong-password':
          err.message = 'Le mot de passe est invalide.';
          break;
        case 'auth/too-many-enquiries':
          err.message = 'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.';
          break;
        case 'auth/email-already-in-use':
          err.message = 'L\'email est déjà utilisé par un autre membre.';
          break;
      }

      return Promise.reject(err);
    }
  }

  async search(data: any): Promise<User[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        data
      );

      const items: User[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as User);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  private signInFirebaseAuth(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(getAuth(), email, password)
        .then((userCredential: UserCredential) => {
          resolve(userCredential);
        })
        .catch(err => {
          switch (err.code) {
            case 'auth/user-mismatch':
              err.message = 'Compte membre introuvable.';
              break;
            case 'auth/user-not-found':
              err.message = 'Compte membre introuvable.';
              break;
            case 'auth/invalid-credential':
              err.message = 'L\'authentification du membre n\'est plus valide.';
              break;
            case 'auth/invalid-email':
              err.message = 'L\'email est invalide.';
              break;
            case 'auth/wrong-password':
              err.message = 'Le mot de passe est invalide.';
              break;
            case 'auth/too-many-enquiries':
              err.message =
                'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.';
              break;
          }

          reject(err);
        });
    });
  }

  private async signOutFirebaseAuth(): Promise<void> {
    await signOut(getAuth());
  }

  private resetPasswordFirebaseAuth(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(getAuth(), email)
        .then(() => {
          resolve();
        })
        .catch(err => {
          switch (err.code) {
            case 'auth/invalid-email':
              err.message = 'L\'email est invalide.';
              break;
            case 'auth/missing-android-pkg-name':
              err.message = 'Un nom de paquêt Android doit être fourni.';
              break;
            case 'auth/missing-continue-uri':
              err.message = 'Url de continuation manquant.';
              break;
            case 'auth/missing-ios-bundle-id':
              err.message = 'Un bundle id pour iOS doit être fourni.';
              break;
            case 'auth/invalid-continue-uri':
              err.message = 'Url de continuation invalide.';
              break;
            case 'auth/unauthorized-continue-uri':
              err.message = 'Le domaine de l\'url de continuation n\'est pas autorisé.';
              break;
            case 'auth/user-not-found':
              err.message = 'Compte membre introuvable.';
              break;
          }

          reject(err);
        });
    });
  }

  private signInWithGoogle(): Promise<UserCredential> {
    return new Promise((resolve, reject) => {
      if (this.platform.is('capacitor')) {
        // Code pour Capacitor
      } else {
        const auth = getAuth();
        auth.languageCode = 'fr';

        const provider = new GoogleAuthProvider();

        provider.setCustomParameters({
          // Localize the authentication screen in French.
          locale: 'fr'
        });

        signInWithPopup(auth, provider)
          .then((userCredential: UserCredential) => {
            resolve(userCredential);
          })
          .catch(err => {
            // Gérer les erreurs
            switch (err.code) {
              case 'auth/user-mismatch':
                err.message = 'Compte membre introuvable.';
                break;
              case 'auth/user-not-found':
                err.message = 'Compte membre introuvable.';
                break;
              case 'auth/invalid-credential':
                err.message = 'L\'authentification du membre n\'est plus valide.';
                break;
              case 'auth/invalid-email':
                err.message = 'L\'email est invalide.';
                break;
              case 'auth/wrong-password':
                err.message = 'Le mot de passe est invalide.';
                break;
              case 'auth/too-many-enquiries':
                err.message =
                  'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.';
                break;
            }

            reject(err);
          });
      }
    });
  }

  private signInWithApple(): Promise<UserCredential> {
    return new Promise((resolve, reject) => {
      if (this.platform.is('capacitor') && this.platform.is('ios')) {
        // Code pour Capacitor
      } else {
        const auth = getAuth();
        auth.languageCode = 'fr';

        const provider = new OAuthProvider('apple.com');

        provider.setCustomParameters({
          // Localize the Apple authentication screen in French.
          locale: 'fr'
        });

        signInWithPopup(auth, provider)
          .then((userCredential: UserCredential) => {
            resolve(userCredential);
          })
          .catch(err => {
            console.log(err);
            switch (err.code) {
              case 'auth/user-mismatch':
                err.message = 'Compte membre introuvable.';
                break;
              case 'auth/user-not-found':
                err.message = 'Compte membre introuvable.';
                break;
              case 'auth/invalid-credential':
                err.message = 'L\'authentification du membre n\'est plus valide.';
                break;
              case 'auth/invalid-email':
                err.message = 'L\'email est invalide.';
                break;
              case 'auth/wrong-password':
                err.message = 'Le mot de passe est invalide.';
                break;
              case 'auth/too-many-enquiries':
                err.message =
                  'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.';
                break;
            }

            reject(err);
          });
      }
    });
  }

  private signInWithFacebook(): Promise<UserCredential> {
    return new Promise(async (resolve, reject) => {
      if (this.platform.is('capacitor')) {
        // Code pour Capacitor
      } else {
        const auth = getAuth();
        auth.languageCode = 'fr';

        const provider = new FacebookAuthProvider();

        provider.addScope('email, public_profile');

        provider.setCustomParameters({
          // Localize the Facebook authentication screen in French.
          locale: 'fr'
        });

        signInWithPopup(auth, provider)
          .then((userCredential: UserCredential) => {
            resolve(userCredential);
          })
          .catch(err => {
            // Gérer les erreurs
            switch (err.code) {
              case 'auth/user-mismatch':
                err.message = 'Compte membre introuvable.';
                break;
              case 'auth/user-not-found':
                err.message = 'Compte membre introuvable.';
                break;
              case 'auth/invalid-credential':
                err.message = 'L\'authentification du membre n\'est plus valide.';
                break;
              case 'auth/invalid-email':
                err.message = 'L\'email est invalide.';
                break;
              case 'auth/wrong-password':
                err.message = 'Le mot de passe est invalide.';
                break;
              case 'auth/too-many-enquiries':
                err.message =
                  'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.';
                break;
              case 'auth/popup-closed-by-user':
                err.message = 'Vous avez fermé la fenêtre de connexion.';
                break;
              case 'auth/account-exists-with-different-credential':
                // User's email already exists.
                // The pending Facebook credential.
                const pendingCred = err.credential;
                // The provider account's email address.
                const email = err.email;
                // Get sign-in methods for this email.

                fetchSignInMethodsForEmail(auth, email).then(async (methods: string[]) => {
                  // Step 3.
                  // If the user has several sign-in methods,
                  // the first method in the list will be the "recommended" method to use.
                  if (methods[0] === 'password') {
                    // Asks the user their password.
                    // In real scenario, you should handle this asynchronously.
                    const alert = await this.alertController.create({
                      message: 'Votre compte existe déjà. Veuillez rappeler votre mot de passe',
                      inputs: [
                        {
                          name: 'password',
                          type: 'password',
                          placeholder: 'Mot de passe'
                        }
                      ],
                      buttons: [
                        {
                          text: 'Annuler',
                          role: 'cancel',
                          cssClass: 'secondary',
                          handler: () => {
                            //console.log('Confirm Cancel');
                          }
                        },
                        {
                          text: 'Continuer',
                          handler: async (result: { password: string }) => {
                            if (result.password) {
                              signInWithEmailAndPassword(auth, email, result.password)
                                .then(
                                  async (resultEmailAndPassword: UserCredential) => {
                                    // Step 4a.
                                    const userCredential: UserCredential =
                                      await linkWithCredential(resultEmailAndPassword.user, pendingCred);

                                    resolve(userCredential);
                                  }
                                );
                              return;
                            }
                          }
                        }
                      ]
                    });

                    await alert.present();
                  } else {
                    //console.log(methods[0]);
                    // All the other cases are external providers.
                    // Construct provider object for that provider.
                    let providerSecondSignIn: any = null;

                    switch (methods[0]) {
                      case 'google.com':
                        providerSecondSignIn = new GoogleAuthProvider();

                        providerSecondSignIn.setCustomParameters({
                          // Localize the authentication screen in French.
                          locale: 'fr'
                        });
                        break;
                    }

                    if (providerSecondSignIn) {
                      // At this point, you should let the user know that they already have an account
                      // but with a different provider, and let them validate the fact they want to
                      // sign in with this provider.
                      // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
                      // so in real scenario you should ask the user to click on a "continue" button
                      // that will trigger the signInWithPopup.

                      const alert = await this.alertController.create({
                        message:
                          'Votre compte existe déjà. Vous allez être inviter à vous connecter avec la méthode de connexion existant. Ceci ne sera à faire que la première fois.',
                        buttons: [
                          {
                            text: 'Annuler',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                              //console.log('Confirm Cancel');
                            }
                          },
                          {
                            text: 'Continuer',
                            handler: async () => {
                              signInWithPopup(auth, providerSecondSignIn)
                                .then((result: UserCredential) => {
                                  // Remember that the user may have signed in with an account that has a different email
                                  // address than the first one. This can happen as Firebase doesn't control the provider's
                                  // sign in flow and the user is free to login using whichever account they own.
                                  // Step 4b.
                                  // Link to Facebook credential.
                                  // As we have access to the pending credential, we can directly call the link method.
                                  linkWithCredential(result.user, pendingCred)
                                    .then((userCredential: UserCredential) => {
                                      // Facebook account successfully linked to the existing Firebase user.
                                      resolve(userCredential);
                                    });
                                });
                            }
                          }
                        ]
                      });

                      await alert.present();
                    }
                  }
                });

                break;
            }

            if (err.code !== 'auth/account-exists-with-different-credential') {
              reject(err);
            }
          });
      }
    });
  }
}
