import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  faChartBar,
  faChartSimple,
  faRoute,
  faEuro
} from '@fortawesome/free-solid-svg-icons';

import { SideMenuService } from 'src/app/services/side-menu.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.page.html',
  styleUrls: ['./subscribe.page.scss']
})
export class SubscribePage implements OnInit {

  faChartBar = faChartBar;
  faChartSimple = faChartSimple;
  faRoute = faRoute;
  faEuro = faEuro;

  constructor(
    private modalController: ModalController,
    private sideMenuService: SideMenuService
  ) {}

  ngOnInit() {}

  welcome(){
    this.modalController.dismiss();
    this.sideMenuService.navigateForward('welcome');
  }

  dismissModal(): void {
    this.modalController.dismiss();
  }
}
