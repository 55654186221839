// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td {
  vertical-align: top;
}
td.vertical-align-middle {
  vertical-align: middle;
}
td .form-group {
  margin-bottom: 0;
}

ion-footer .form-group {
  text-align: center;
}
ion-footer .form-group .checkbox-container {
  display: inline-block;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/app/profile/quotations/quotation-edit/quotation-edit.page.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,sBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;;AAKE;EACE,kBAAA;AAFJ;AAGI;EACE,qBAAA;EACA,YAAA;AADN","sourcesContent":["td {\r\n  vertical-align: top;\r\n\r\n  &.vertical-align-middle {\r\n    vertical-align: middle;\r\n  }\r\n\r\n  .form-group {\r\n    margin-bottom: 0;\r\n  }\r\n}\r\n\r\nion-footer {\r\n  .form-group {\r\n    text-align: center;\r\n    .checkbox-container {\r\n      display: inline-block;\r\n      margin: auto;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
