import { Injectable } from '@angular/core';
import { Storage, ref, uploadString, UploadTask, StorageReference, deleteObject } from '@angular/fire/storage';  // Assurez-vous que deleteObject est inclus ici
import { getDownloadURL, StringFormat } from '@firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentService {
  constructor(public storage: Storage) {}

  upload(
    folder = '',
    filename = null,
    extension = '',
    file: any,
    encoding: StringFormat = StringFormat.RAW
  ): Promise<{ ref: StorageReference; task: UploadTask }> {
    if (!filename) {
      filename = this.generateUUID();
    }

    const fileRef = ref(this.storage, folder + '/' + filename + extension);
    const task = uploadString(fileRef, file, encoding);

    return Promise.resolve({
      ref: fileRef,
      task: task as unknown as UploadTask
    });
  }

  deleteFile(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const fileRef: StorageReference = ref(this.storage, url);

      deleteObject(fileRef)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  generateUUID(): string {
    let d: number = new Date().getTime();

    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      // eslint-disable-next-line no-bitwise
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });

    return uuid;
  }
}
