// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  font-size: 16px;
}

ion-searchbar {
  --border-radius: 30px;
  margin-top: 20px;
  --box-shadow: none;
}

ion-button {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/app/spot/spot-suggestion-station/spot-suggestion-station.page.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,qBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":["p {\r\n    font-size: 16px;\r\n}\r\n\r\nion-searchbar {\r\n    --border-radius: 30px;\r\n    margin-top: 20px;\r\n    --box-shadow: none;\r\n}\r\n\r\nion-button {\r\n    margin-top: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
