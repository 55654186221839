// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  border-radius: 0;
  --padding-bottom: 0px;
}
ion-toolbar .back-button {
  background-color: var(--ion-color-sidepanel-header);
  color: var(--ion-color-sidepanel-header-contrast);
  --background-hover-opacity: 0;
}
ion-toolbar ion-title {
  font-size: 18px;
  color: var(--ion-color-sidepanel-header-contrast);
}

ion-content {
  --max-width: 500px;
  --margin: 20px auto;
  --padding-top: 0;
}
ion-content .header {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 40px;
}
ion-content ion-button {
  width: 100%;
  margin-top: 20px;
}
ion-content ion-input {
  background-color: var(--ion-card-background);
  color: var(--ion-text-color);
  --placeholder-color: rgba(var(--ion-text-color-rgb), 0.7);
}`, "",{"version":3,"sources":["webpack://./src/app/forgot-password/forgot-password.page.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,qBAAA;AACF;AACE;EACE,mDAAA;EACA,iDAAA;EACA,6BAAA;AACJ;AAEE;EACE,eAAA;EACA,iDAAA;AAAJ;;AAIA;EAKE,kBAAA;EACA,mBAAA;EACA,gBAAA;AALF;AAOE;EACE,YAAA;EACA,gBAAA;EACA,mBAAA;AALJ;AAQE;EACE,WAAA;EACA,gBAAA;AANJ;AASE;EACE,4CAAA;EACA,4BAAA;EACA,yDAAA;AAPJ","sourcesContent":["ion-toolbar {\r\n  border-radius: 0;\r\n  --padding-bottom: 0px;\r\n\r\n  .back-button {\r\n    background-color: var(--ion-color-sidepanel-header);\r\n    color: var(--ion-color-sidepanel-header-contrast);\r\n    --background-hover-opacity: 0;\r\n  }\r\n\r\n  ion-title {\r\n    font-size: 18px;\r\n    color: var(--ion-color-sidepanel-header-contrast);\r\n  }\r\n}\r\n\r\nion-content {\r\n  // --background: url(../../assets/img/splash.png) bottom center / contain no-repeat\r\n  //   var(--ion-color-lightblue);\r\n  // --color: white;\r\n\r\n  --max-width: 500px;\r\n  --margin: 20px auto;\r\n  --padding-top: 0;\r\n\r\n  .header {\r\n    margin: auto;\r\n    margin-top: 30px;\r\n    margin-bottom: 40px;\r\n  }\r\n\r\n  ion-button {\r\n    width: 100%;\r\n    margin-top: 20px;\r\n  }\r\n\r\n  ion-input {\r\n    background-color: var(--ion-card-background);\r\n    color: var(--ion-text-color);\r\n    --placeholder-color: rgba(var(--ion-text-color-rgb), 0.7);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
